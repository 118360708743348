<template>
  <div class="content">
    <div class="content__title content__title--party content__title--start">
      <router-link
        :to="{ name: 'profile-personal' }"
        class="button button--light button--no-indent button--small"
      >
        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.70917 13.7082C7.61629 13.8013 7.50595 13.8752 7.38447 13.9256C7.263 13.976 7.13277 14.002 7.00125 14.002C6.86973 14.002 6.7395 13.976 6.61802 13.9256C6.49655 13.8752 6.38621 13.8013 6.29332 13.7082L0.293962 7.70884C0.200846 7.61595 0.126968 7.50562 0.0765611 7.38414C0.0261537 7.26266 0.000206594 7.13243 0.000206606 7.00091C0.000206617 6.86939 0.0261537 6.73916 0.0765611 6.61769C0.126969 6.49621 0.200846 6.38587 0.293962 6.29299L6.29333 0.293626C6.48108 0.105873 6.73573 0.00039471 7.00125 0.000394734C7.26677 0.000394757 7.52142 0.105873 7.70918 0.293626C7.89693 0.481379 8.00241 0.736028 8.00241 1.00155C8.00241 1.26707 7.89693 1.52172 7.70917 1.70948L2.41574 7.00091L7.70917 12.2923C7.80229 12.3852 7.87617 12.4956 7.92658 12.617C7.97698 12.7385 8.00293 12.8688 8.00293 13.0003C8.00293 13.1318 7.97698 13.262 7.92658 13.3835C7.87617 13.505 7.80229 13.6153 7.70917 13.7082Z" fill="#2B93E7"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M16 7.00108C16 7.26627 15.8947 7.5206 15.7071 7.70811C15.5196 7.89563 15.2653 8.00098 15.0001 8.00098L2.00149 8.00098C1.7363 8.00098 1.48197 7.89563 1.29445 7.70811C1.10694 7.5206 1.00159 7.26627 1.00159 7.00108C1.00159 6.73589 1.10694 6.48156 1.29445 6.29405C1.48197 6.10653 1.7363 6.00119 2.00149 6.00119L15.0001 6.00119C15.2653 6.00119 15.5196 6.10653 15.7071 6.29405C15.8947 6.48157 16 6.73589 16 7.00108Z" fill="#2B93E7"/>
        </svg>
      </router-link>
      <h1 class="title title--big title--theme">Редактирование паспортных данных</h1>
    </div>
    <form @submit.prevent="onCheckForm">
      <app-grid>
        <template #item-1>
          <app-form-group label="Гражданство" required>
            <v-select
              v-model="form.citizenship_id"
              :options="citizenship_options"
              :reduce="citizenship => citizenship.id"
              :searchable="false"
              :clearable="false"
              label="name"
              class="select"
              :class="{ 'select--error': $v.form.citizenship_id.$error }"
              placeholder="Выберите значение"
            >
              <template slot="open-indicator">
                <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                  <path d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z" fill="#fff"></path>
                </svg>
              </template>
              <template slot="option" slot-scope="option">
                <div class="select__item">{{ option.name }}</div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="d-center">{{ option.name }}</div>
              </template>
            </v-select>
            <template #error>
              <div v-if="$v.form.citizenship_id.$dirty && !$v.form.citizenship_id.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group v-if="form.citizenship_id === 'rf'" label-for="passport_series_rf" label="Серия паспорта" required>
            <app-input
              v-model.trim="form.passport_series"
              @change.native="$v.form.passport_series.$touch()"
              :error="$v.form.passport_series.$error"
              id="passport_series_rf"
              name="passport_series_rf"
              placeholder="Введите серию паспорта"
             />
            <template #error>
              <div v-if="$v.form.passport_series.$dirty && !$v.form.passport_series.required">
                Обязательное поле
              </div>
              <div v-if="$v.form.passport_series.$dirty && !$v.form.passport_series.numeric">
                Принимаются только цифры
              </div>
              <div v-if="$v.form.passport_series.$dirty && !$v.form.passport_series.minLength">
                Серия паспорта не может быть меньше 4 цифр
              </div>
              <div v-if="$v.form.passport_series.$dirty && !$v.form.passport_series.maxLength">
                Серия паспорта не может быть больше 4 цифр
              </div>
            </template>
          </app-form-group>
          <app-form-group v-else label-for="passport_series" label="Серия паспорта">
            <app-input
              id="passport_series"
              name="passport_series"
              v-model.trim="form.passport_series"
              @change.native="$v.form.passport_series.$touch()"
              :error="$v.form.passport_series.$error"
              placeholder="Введите серию паспорта"
            />
          </app-form-group>
          <app-form-group v-if="form.citizenship_id === 'rf'" label-for="passport_number_rf" label="Номер паспорта" required>
            <app-input
              id="passport_number_rf"
              type="text"
              class="form-control__input"
              name="passport_number_rf"
              v-model.trim="form.passport_number"
              @change.native="$v.form.passport_number.$touch()"
              :error="$v.form.passport_number.$error"
              placeholder="Введите номер паспорта"
            />
            <template #error>
              <div v-if="$v.form.passport_number.$dirty && !$v.form.passport_number.required">
                Обязательное поле
              </div>
              <div v-if="$v.form.passport_number.$dirty && !$v.form.passport_number.numeric">
                Принимаются только цифры
              </div>
              <div v-if="$v.form.passport_number.$dirty && !$v.form.passport_number.minLength">
                Номер паспорта не может быть меньше 6 цифр
              </div>
              <div v-if="$v.form.passport_number.$dirty && !$v.form.passport_number.maxLength">
                Номер паспорта не может быть больше 6 цифр
              </div>
            </template>
          </app-form-group>
          <app-form-group v-else label-for="passport_number" label="Номер паспорта" required>
            <app-input
              id="passport_number"
              type="text"
              class="form-control__input"
              name="passport_number"
              v-model.trim="form.passport_number"
              @change.native="$v.form.passport_number.$touch()"
              :error="$v.form.passport_number.$error"
              placeholder="Введите номер паспорта"
            />
            <template #error>
              <div v-if="$v.form.passport_number.$dirty && !$v.form.passport_number.required">
                Обязательное поле
              </div>
            </template>
          </app-form-group>
          <app-form-group v-if="form.citizenship_id && form.citizenship_id === 'rf'" label="Кем выдан паспорт">
            <v-select
              label="name"
              v-model="form.passport_fms"
              :filterable="false"
              :options="fms_code_options"
              :clearable="false"
              @search="onFmsCodeSearch"
              @input="onSetFmsCode"
              placeholder="Выберите код подразделения"
              class="select"
              :key="1"
            >
              <template slot="open-indicator">
                <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="none">
                  <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
              </template>
              <template #no-options="{ search, searching }">
                <div v-if="searching" class="select__noty">
                  По вашему запросу <em>{{ search }}</em> ничего не найдено.
                </div>
                <div v-else class="select__noty">
                  Для поиска подразделения выдавшего паспорт в справочнике, введите код подразделения в формате: <span>260-012</span> или полное наименование <span>ГУ МВД РОССИИ ПО СТАВРОПОЛЬСКОМУ КРАЮ</span> и выберите значение из выпадающего списка.
                </div>
              </template>
              <template slot="option" slot-scope="option">
                <div class="select__item d-center">{{ option.name }} ({{ option.code }}) </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">{{ option.name }} ({{ option.code }})</div>
              </template>
            </v-select>
            <div
              v-if="form.passport_fms_name && form.passport_fms_name.length > 50"
              class="select__long"
            >
              {{ form.passport_fms_name }} ({{ form.passport_fms_code }})
            </div>
          </app-form-group>
          <app-form-group v-if="form.citizenship_id && form.citizenship_id !== 'rf'" label-for="passport_fms_name" label="Кем выдан паспорт">
            <app-input
              id="passport_fms_name"
              name="passport_fms_name"
              v-model.trim="form.passport_fms_name"
              placeholder="Введите кем выдан паспорт"
              :key="2"
            />
          </app-form-group>
          <app-form-group label-for="passport_date" label="Дата выдачи паспорта">
            <app-input
              v-model="form.passport_date"
              v-mask="'99.99.9999'"
              @input.native="onCheckPassportDate"
              id="passport_date"
              placeholder="дд.мм.гггг"
              :class="{ 'input--error': $v.form.passport_date.$error || $v.passport_age.$invalid }"
              autocomplete="off"
              @paste.native.prevent
              :key="3"
            />
            <template #error>
              <div v-if="$v.form.passport_date.$dirty && !$v.passport_age.between">Некорректная дата</div>
            </template>
          </app-form-group>
          <app-form-group v-if="form.citizenship_id && form.citizenship_id !== 'rf'" label-for="passport_date_end" label="Срок действия паспорта">
            <app-input
              v-model="form.passport_date_end"
              v-mask="'99.99.9999'"
              @input.native="onCheckPassportEndDate"
              id="passport_date_end"
              :class="{ 'input--error': $v.form.passport_date_end.$error || $v.passport_end_age.$invalid }"
              placeholder="дд.мм.гггг"
              autocomplete="off"
              @paste.native.prevent
              :key="4"
            />
            <template #error>
              <div v-if="$v.form.passport_date_end.$dirty && !$v.passport_end_age.between">Некорректная дата</div>
            </template>
          </app-form-group>
        </template>
        <template #item-2>
          <app-form-group label-for="passport_birthplace" label="Место рождения">
            <app-input
              v-model.trim="form.passport_birthplace"
              id="passport_birthplace"
              name="passport_birthplace"
              placeholder="Введите место своего рождения"
              :key="5"
            />
          </app-form-group>
          <app-form-group label="Скан-копия основного разворота паспорта">
            <app-uploader
              v-model="form.passport_main_page"
            />
          </app-form-group>
          <app-form-group label="Скан-копия разворота с регистрацией или временная регистрация">
            <app-uploader
              v-model="form.passport_second_page"
            />
          </app-form-group>
          <app-form-group v-show="form.citizenship_id && form.citizenship_id !== 'rf'" label="Скан-копия страницы с действующей визой">
            <app-uploader
              v-model="form.visa"
            />
          </app-form-group>
        </template>
      </app-grid>
      <app-cells>
        <app-button ref="submit" :disabled="$v.form.$error">Сохранить изменения</app-button>
      </app-cells>
    </form>
  </div>
</template>

<script>
import { debounce, omit } from 'lodash'
import { required, numeric, minLength, maxLength, alphaNum, or, helpers, between } from 'vuelidate/lib/validators'
import { differenceInCalendarYears, parse } from 'date-fns'

const ruAlpha = helpers.regex('ruAlpha', /[а-яёА-ЯЁ]/i)

export default {
  name: 'ProfilePersonalPassportEdit',
  data() {
    return {
      form: {},
      citizenship_options: [],
      fms_code_options: [],
      passport_birthplace_options: [],
      passport_age: 25,
      passport_end_age: 25,
    }
  },
  validations() {
    const form = {
      citizenship_id: { required },
      passport_series: {},
      passport_number: { required },
      passport_date: {},
      passport_date_end: {},
    }

    if (this.form.citizenship_id === 'rf') {
      form.passport_series = { required, numeric, minLength: minLength(4), maxLength: maxLength(4) }
      form.passport_number = { required, numeric, minLength: minLength(6), maxLength: maxLength(6) }
    } else {
      form.passport_series = { or: or(alphaNum, ruAlpha) }
      form.passport_number = { required, or: or(alphaNum, ruAlpha) }
    }

    return { form, passport_age: { between: between(14, 90) }, passport_end_age: { between: between(-30, 90) } }
  },
  mounted() {
    this.$store.dispatch('dict/GET_CITIZENSHIP')
      .then(response => { this.citizenship_options = response.data })
    this.$store.dispatch('profile/passport/GET_DATA')
      .then(response => {
        this.form = response.data
        if (response.data.visa && response.data.visa_url) {
          this.form.visa = {
            id: response.data.visa,
            file: response.data.visa_url
          }
        }
        if (response.data.passport_main_page && response.data.passport_main_page_url) {
          this.form.passport_main_page = {
            id: response.data.passport_main_page,
            file: response.data.passport_main_page_url
          }
        }
        if (response.data.passport_second_page && response.data.passport_second_page_url) {
          this.form.passport_second_page = {
            id: response.data.passport_second_page,
            file: response.data.passport_second_page_url
          }
        }
        if (!response.data.passport_fms_code && !response.data.passport_fms_name) this.form.passport_fms = null
      })
  },
  methods: {
    onFmsCodeSearch(search, loading) {
      loading(true)
      this.searchFmsCode(loading, search, this)
    },
    searchFmsCode: debounce((loading, search, vm) => {
      vm.$store.dispatch('search/GET_FMS', {search}).then(response => {
        vm.fms_code_options = response.data
        loading(false)
      })
    }, 350),
    onPassportBirthplaceSearch(search, loading) {
      loading(true)
      this.searchPassportBirthplace(loading, search, this)
    },
    searchPassportBirthplace: debounce((loading, search, vm) => {
      vm.$store.dispatch('search/GET_ADDRESS_CITY', {search}).then(response => {
        vm.passport_birthplace_options = response.data
        loading(false)
      })
    }, 350),
    onSetFmsCode() {
      this.form.passport_fms_code = this.form.passport_fms.code
      this.form.passport_fms_name = this.form.passport_fms.name
    },
    onCheckPassportDate() {
      this.$v.form.passport_date.$touch()
      const date = parse(this.form.passport_date, 'dd.MM.yyyy', new Date())
      this.passport_age = differenceInCalendarYears(new Date(), date)
    },
    onCheckPassportEndDate() {
      this.$v.form.passport_date_end.$touch()
      const date = parse(this.form.passport_date_end, 'dd.MM.yyyy', new Date())
      this.passport_end_age = differenceInCalendarYears(new Date(), date)
    },
    onCheckForm() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Проверьте правильность заполнения полей формы.'
        })
      } else {
        this.sendForm()
      }
    },
    sendForm() {
      this.$refs.submit.preload = true
      this.$store
        .dispatch('profile/passport/PATCH_DATA', this.normalizeForm())
        .then(() => {
          this.$refs.submit.preload = false
          this.$router.push({ name: 'profile-personal' })
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Паспортные данные успешно сохранены.'
          })
        })
        .catch(error => {
          this.$refs.submit.preload = false
          for (const key in error.response.data) {
            if (typeof error.response.data[key] === 'string') {
              this.$notify({
                type: 'error',
                text: error.response.data[key]
              })
            } else {
              this.$notify({
                type: 'error',
                text: error.response.data[key][0]
              })
            }
          }
        })
    },
    normalizeForm() {
      let { ...normalizedForm } = this.form
      if (!normalizedForm.passport_date_end) {
        normalizedForm = omit(this.form, ['passport_date_end'])
      }
      if (normalizedForm?.visa?.id) normalizedForm.visa = normalizedForm.visa.id
      else {
        normalizedForm.visa = ''
        normalizedForm.visa_url = ''
      }
      if (normalizedForm?.passport_main_page?.id) normalizedForm.passport_main_page = normalizedForm.passport_main_page.id
      else {
        normalizedForm.passport_main_page = ''
        normalizedForm.passport_main_page_url = ''
      }
      if (normalizedForm?.passport_second_page?.id) normalizedForm.passport_second_page = normalizedForm.passport_second_page.id
      else {
        normalizedForm.passport_second_page = ''
        normalizedForm.passport_second_page_url = ''
      }
      return normalizedForm
    },
  }
}
</script>
